import React from 'react';
import { Link } from 'gatsby';
import { useArticles } from '../hooks/useArticles';
import Img from 'gatsby-image';
import './articles.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => {
  const articles = useArticles();

  return (
    <Layout>
      <SEO title="Articles" />
      <ul className="articles">
        {articles.map(article => (
          <li key={article.slug}>
            <Link to={`/articles/${article.slug}`}>
              <div>
                <h1>{article.heading}</h1>
                <div className="timestamp hidden-mobile">
                  {article.postedAt}
                </div>
              </div>
              <Img
                fluid={article.featureImage.localFiles[0].childImageSharp.fluid}
                title={article.imageCredit.childMarkdownRemark.plainText.trim()}
              />
              <div className="timestamp show-mobile">{article.postedAt}</div>
            </Link>
          </li>
        ))}
      </ul>
      <div style={{ height: '400px' }} />
    </Layout>
  );
};
